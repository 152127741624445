import React from 'react';
import ProjectTile from './ProjectTile';
import FadeIn from './FadeIn';

export default function ProjectList(props) {
	return (
		<div className="grid grid-cols-1 lg:grid-cols-2 gap-4 gap-x-8 lg:gap-x-16 gap-y-4 lg:gap-y-8 pb-8 lg:pb-16">
			<div className="hidden lg:block"></div>
			{props.projects.map((project) => (
				<FadeIn>
					<ProjectTile project={project} />
				</FadeIn>
			))}
		</div>
	);
}
