import React from 'react';
import { graphql } from 'gatsby';
import ArchiveHeader from '../components/ArchiveHeader';
import ProjectList from '../components/ProjectList';
import Seo from '../components/Seo';

const ProjectPage = ({ data }) => {
	const page = data && data.index;
	return (
		<>
			<Seo
				metaTitle={page.seo?.metaTitle || page.title}
				shareTitle={page.seo?.shareTitle || page.title}
				metaDesc={page.seo?.metaDesc}
				shareDesc={page.seo?.shareDesc}
			/>
			<ArchiveHeader index={page} categories={data.allCategories} />
			<ProjectList projects={data.projects.nodes} />
		</>
	);
};

export default ProjectPage;

export const query = graphql`
	query ProjectQuery {
		index: sanityProjectsIndex {
			id
			title
			slug {
				current
			}
			seo {
				...seoFields
			}
		}
		allCategories: allSanityProjectCategories(
			sort: { fields: orderRank, order: ASC }
		) {
			nodes {
				id
				title
				slug {
					current
				}
			}
		}
		projects: allSanityProject(
			sort: { fields: [_createdAt], order: DESC }
		) {
			nodes {
				id
				title
				slug {
					current
				}
				categories {
					title
				}
				tileImage {
					asset {
						gatsbyImageData(
							layout: FULL_WIDTH
							fit: FILLMAX
							placeholder: DOMINANT_COLOR
						)
					}
				}
				tileHoverImage {
					asset {
						gatsbyImageData(
							layout: FULL_WIDTH
							fit: FILLMAX
							placeholder: DOMINANT_COLOR
						)
					}
				}
			}
		}
	}
`;
